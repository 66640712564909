// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

/* eslint-disable */

import Rails from '@rails/ujs'
import * as ActiveStorage from '@rails/activestorage'
import 'channels'

Rails.start()
ActiveStorage.start()

window.addEventListener('load', () => {
  navigator.serviceWorker.register('/packs/service_worker.js', { scope: '/' }).then((registration) => {
    let serviceWorker
    if (registration.installing) {
      serviceWorker = registration.installing
    } else if (registration.waiting) {
      serviceWorker = registration.waiting
    } else if (registration.active) {
      serviceWorker = registration.active
    }
  }).catch((registrationError) => {
    console.log('Service worker registration failed: ', registrationError)
  })
})

/* eslint-enable */
