/* eslint-disable import/prefer-default-export */
import ActionCable from 'action-cable-react-jwt'
import consumer from './consumer'

export const MessagesChannel = (room_id) => {
  const yourToken = localStorage.getItem('token')
  const App = {}
  App.cable = ActionCable.createConsumer('ws://localhost:3000/cable', yourToken)
  return consumer.subscriptions.create({ channel: 'MessagesChannel', room_id }, {
    connected() {
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    received() {
    },
  })
}
